<template>
  <b-modal id="confirmRoleChangeModal" centered hide-header-close title="Confirm Role Change" :hide-footer=isSubmitting>
      <template #default>
        <div  v-if="!isSubmitting" class="text-center" style="height: 500px !important; overflow-y: scroll;">
          <p> {{ username }} will be removed as project administrator from all projects listed below</p>
          <b-list-group>
            <b-list-group-item class="mt-1" v-for="(item, id) in projectList" :key="id" style="border: 1px solid rgba(0, 0, 0, 0.125) !important;"> {{ item }}</b-list-group-item>
          </b-list-group>
        </div>
      </template>
      <template #modal-footer>
          <b-button size="sm" @click='handleCancel()'  class='px-4 py-0 float-left remove-project-btn'> Cancel </b-button>    
          <b-button size="sm" variant="danger" @click="confirmSubmission()" class='px-4 py-0 float-right remove-project-btn'> Ok </b-button>    
      </template>
  </b-modal>
</template>
<script>
export default {
  name: 'ConfirmRoleModal',
  props: {
    modalStatus: {
      type: Number,
      required: false
    },
    projectList: {
      type: Array,
      required: false
    },
    username: {
      type: String,
      required: false
    },
  },
  data(){
    return {
      isSubmitting: false
    };
  },
  methods: {
    async handleCancel(){
      this.$emit('cancelViewerRoleChange'); 
      this.$bvModal.hide('confirmRoleChangeModal');
    },
    confirmSubmission(){
      this.isSubmitting = true;
      this.$emit('changeViewerRole'); 
      this.$bvModal.hide('confirmRoleChangeModal');
    }
  },
  watch: {
    modalStatus(oldValue, newValue){
      if(oldValue != newValue)
        this.$bvModal.show('confirmRoleChangeModal');
    }
  }

};
</script>
